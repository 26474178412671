<template>
    <div>Esporta Tipi Comitato</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipi Comitato", route: "/settings/committee-type/index" },
             { title: "Esporta Tipi Comitato" }
         ]);
     }
 };
</script>
